<script lang="ts">
    import { OverlayScrollbars } from 'overlayscrollbars';
    import { goto } from '$app/navigation';
    import { Button, Skeleton } from '@allibee/components/basic';
    import { actionSignOut, getLauncher, makeWorkspaceName } from '$stores/auth';
    import { onMount } from 'svelte';
    import LogoAllibee from '$lib/service/logo/LogoAllibee.svelte';
    import LauncherWorkspaceCard from './LauncherWorkspaceCard.svelte';
    import { _ } from 'svelte-i18n';
    import { page } from '$app/stores';

    const { transformUrl } = $page.data.master;

    let scrollAreaRef: HTMLDivElement;
    $: if (scrollAreaRef) {
        OverlayScrollbars(scrollAreaRef, {});
    }

    let launchers: any[] = [];
    let isLoading = true;

    onMount(async () => {
        try {
            launchers = await getLauncher();

            // 워크스페이스가 없으면 워크스페이스 생성 페이지로 이동
            if (launchers.length === 0) {
                goto(transformUrl('/workspace/create'));
            }
        } catch (err) {
            console.error(err);
            throw err;
        } finally {
            isLoading = false;
        }
    });

    const handleSignOut = async () => {
        await actionSignOut();
    };

    const handleCreateWorkspace = () => {
        goto(transformUrl('/workspace/create'));
    };
</script>

<div bind:this={scrollAreaRef}>
    <div class="relative flex flex-col items-center gap-8 bg-white px-20 py-20">
        <div class="flex flex-col items-center">
            <LogoAllibee class="h-8" color="GREEN" />

            {#if isLoading}
                <p class="mb-5 whitespace-pre-wrap text-base leading-6 text-neutral-900" aria-label={$_('routes.launcher.loadingWorkspaceAndProducts')}>
                    {$_('routes.launcher.loadingWorkspaceAndProducts')}
                </p>
                <Skeleton class="h-32 w-96" />
            {:else if launchers.length === 0}
                <p class="mt-3 whitespace-pre-wrap text-center text-base leading-6 text-neutral-900">
                    {$_('routes.launcher.noWorkspace')}
                </p>
                <div class="relative mb-5 text-center font-bold text-gray-900">support@bhsn.ai</div>
                <Button on:click={handleSignOut}>{$_('routes.launcher.logout')}</Button>
            {:else}
                <p class="mb-8 mt-4 whitespace-nowrap text-base leading-6 text-neutral-900" aria-label={$_('routes.launcher.catchphrase')}>
                    {$_('routes.launcher.catchphrase')}
                </p>
                <div>
                    <div class="mb-6 flex flex-col gap-4">
                        {#each launchers as launcher}
                            <a href={transformUrl(`/${makeWorkspaceName(launcher.tenantId, launcher.workspaceId)}/`)}>
                                <!-- plugin 에 대해서 어떻게 할지가 정해지지 않음 -->
                                <LauncherWorkspaceCard username={launcher.displayName} workspaceName={launcher.workspaceName} hasAiPlugin={false} subscriptionStatus={launcher.subscriptionStatus} />
                            </a>
                        {:else}
                            <div
                                class="inline-flex w-96 items-center justify-start gap-4 rounded-lg border border-gray-200 bg-white p-4 text-left shadow-lg hover:bg-gray-100"
                                on:click={handleCreateWorkspace}
                                on:keydown={e => e.key === 'Enter' && handleCreateWorkspace()}
                                role="button"
                                tabindex="0"
                            >
                                <div class="flex h-10 w-10 items-center justify-center rounded-lg bg-gray-100">
                                    <svg class="h-6 w-6" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M17.9945 9.5957V18.4926M17.9945 18.4926V27.3895M17.9945 18.4926H26.8914M17.9945 18.4926H9.09766"
                                            stroke="#111928"
                                            stroke-width="2.07594"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </div>
                                <div class="shrink grow basis-0 text-sm font-medium text-gray-900">
                                    {$_('routes.launcher.actionCreateWorkspace')}
                                </div>
                            </div>
                        {/each}
                    </div>
                    <div class="inline-flex h-14 w-96 items-center justify-center">
                        <button class="flex gap-1" on:click={handleSignOut}>
                            <div class="flex h-6 w-6 items-center justify-center rounded px-0.5 py-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                                    <path
                                        d="M15 13L19 9M19 9L15 5M19 9H5M11 13V14C11 14.7956 10.6839 15.5587 10.1213 16.1213C9.55871 16.6839 8.79565 17 8 17H4C3.20435 17 2.44129 16.6839 1.87868 16.1213C1.31607 15.5587 1 14.7956 1 14V4C1 3.20435 1.31607 2.44129 1.87868 1.87868C2.44129 1.31607 3.20435 1 4 1H8C8.79565 1 9.55871 1.31607 10.1213 1.87868C10.6839 2.44129 11 3.20435 11 4V5"
                                        stroke="#434343"
                                        stroke-width="1.4"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>
                            <div class="text-base font-normal leading-normal text-neutral-700">
                                {$_('routes.launcher.actionLogout')}
                            </div>
                        </button>
                    </div>
                </div>
            {/if}
        </div>
    </div>
</div>
